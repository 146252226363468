
import { useMarketStore } from '@/stores/market'

export default {
  name: 'MPFilterCategory',
  props: {
    listCategories: {
      type: Array,
      default: () => {},
    },
  },
  setup() {
    const marketStore = useMarketStore()

    return { marketStore }
  },
  data() {
    return {
      arrowNext: false,
      arrowPrev: false,
      fixedFilter: false,

      category_list_size: 30,
      inThrottle: false,
    }
  },
  type: {
    type: String,
    default: '',
  },
  computed: {
    showListCategory() {
      return this.listCategories.slice(0, this.category_list_size)
    },
  },
  watch: {
    $route() {
      this.setCategory()
      this.setArrowsScroll()
    },
  },
  mounted() {
    this.setCategory()
    this.setArrowsScroll()
  },
  destroyed() {
    document.removeEventListener('scroll', this.setScroll)
    document
      .getElementsByClassName('Layout')[0]
      .removeEventListener('scroll', this.setScroll)
    window.removeEventListener('resize', this.setArrowsScroll)
    window.removeEventListener('resize', this.setDocumentScrollListener)
  },
  async beforeMount() {
    if (process.client) {
      this.setDocumentScrollListener()
      window.addEventListener('resize', this.setArrowsScroll)
      window.addEventListener('resize', this.setDocumentScrollListener)
    }
  },
  methods: {
    async filterCategory(category_id) {
      const routeQuery = { ...this.$route.query }
      if (category_id) {
        routeQuery.category = category_id
      } else {
        delete routeQuery.category
      }
      delete routeQuery.page

      if (this.$route.query?.['q[s]']) {
        routeQuery['q[s]'] = this.$route.query?.['q[s]']
      }
      this.$nextTick(() => {
        try {
          this.$router.push({
            path: this.localePath({
              name: this.$route.name.replace(
                `___${this.$i18n.locale?.toLowerCase()}`,
                '',
              ),
              params: { ...this.$route.params },
              query: { ...routeQuery },
            }),
          })
        } catch (error) {
          if (error.name !== 'NavigationDuplicated') throw error
        }
      })
    },
    setCategory() {
      const routeQuery = JSON.parse(JSON.stringify(this.$route.query))
      this.marketStore.defaultCategoryMegaDiscounts = routeQuery?.category
        ? +routeQuery?.category
        : 0
    },
    setArrowsScroll() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.arrowNext =
            this.$refs['MPFilterCategory-List']?.clientWidth >
            this.$refs.MPFilterCategory?.clientWidth
        }, 200)
      })
    },
    scrollWrapper(e) {
      this.arrowPrev = e.target.scrollLeft > 0
      this.arrowNext =
        this.$refs['MPFilterCategory-List']?.clientWidth >
        e.target.scrollLeft + this.$refs.MPFilterCategory?.offsetWidth + 10
      if (!this.inThrottle) {
        if (this.listCategories?.length > this.category_list_size) {
          this.category_list_size += 10
        }
        this.inThrottle = true
        setTimeout(() => (this.inThrottle = false), 500)
      }
    },
    scrollNext() {
      if (this.listCategories?.length > this.category_list_size) {
        this.category_list_size += 10
      }
      this.$refs.MPFilterCategoryWrapper.scrollLeft +=
        this.$refs.MPFilterCategoryWrapper?.offsetWidth
    },
    scrollPrev() {
      this.$refs.MPFilterCategoryWrapper.scrollLeft -=
        this.$refs.MPFilterCategoryWrapper?.offsetWidth
    },
    setScroll() {
      if (document.getElementsByClassName('Layout')[0]) {
        if (
          document.getElementsByClassName('NewAppHeader') &&
          document.getElementsByClassName('NewAppHeader')[0]
        ) {
          const heightHeader =
            document.getElementsByClassName('NewAppHeader')[0].offsetHeight
          const scrollToTopDocument = document
            .getElementsByClassName('MPFilterCategory-Content')?.[0]
            ?.getBoundingClientRect().top
          this.fixedFilter = heightHeader > scrollToTopDocument
        }
      }
    },
    setDocumentScrollListener() {
      this.setScroll()
      const LayoutElement = document.getElementsByClassName('Layout')?.[0]
      if (LayoutElement) {
        if (window.innerWidth > 768 && document) {
          LayoutElement.addEventListener('scroll', this.setScroll)
          document.removeEventListener('scroll', this.setScroll)
        } else {
          LayoutElement.removeEventListener('scroll', this.setScroll)
          document.addEventListener('scroll', this.setScroll)
        }
      }
    },
  },
}
